import { loadLoginView } from "./views/login";
import { router } from "./modules/base/router/Router";
import { loadLayout, loadTab } from "./views/main";
import { usuarioStore } from "./modules/common/usuario/infraestructure/api/UsuarioStore";
import { loginToken } from "./modules/common/usuario/application/Login";


router.addRoute('', async () => {
    await loginToken(usuarioStore, false);
    loadLayout();
    loadTab('common', 'facturas_gruas', 'Factura Grúas');
});

router.addRoute('login', async () => {
    loadLoginView();
});

router.init();
