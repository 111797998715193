import { loadTab } from "../../../views/main";
import { MenuItem, MenuData } from "../../base/@types/Menu";

let isSidebarInitialized = false;

export function initSidebar() {
  if (isSidebarInitialized) {
    return;
  }

  const sidebar = document.getElementById("sidebar");

  if (sidebar) {
    fetch('src/config/sidebar.json')
      .then(response => response.json())
      .then(data => {
        sidebar.innerHTML = `
          <aside id="layout-menu" class="layout-menu-horizontal menu-horizontal menu bg-menu-theme flex-grow-0" style="z-index: 1040;">
            <div class="container-fluid d-flex h-100" >
              <ul class="menu-inner">
                ${generateMenuHTML(data)}
              </ul>
            </div>
          </aside>
        `;

        sidebar.addEventListener("click", (e) => {
          let target = e.target as HTMLElement;

          if (target.closest("a.menu-link")) {
            target = target.closest("a.menu-link") as HTMLElement;
          }

          // Acción al hacer click en los enlaces
          if (target instanceof HTMLAnchorElement && target.dataset.view) {
            e.preventDefault();
            const module = target.dataset.module || "";
            const view = target.dataset.view || "";
            const title = target.dataset.title || "";
            console.log(module, view, title);
            loadTab(module, view, title);
          }

          // Detectar si es un enlace de submenú
          if (target.closest(".menu-toggle")) {
            const parentMenuItem = target.closest(".menu-item");
            if (parentMenuItem) {
              parentMenuItem.classList.toggle("open");
            }
          }
        });

        const menuItemsWithSub = sidebar.querySelectorAll(
          ".menu-item.menu-has-sub"
        );
        menuItemsWithSub.forEach((menuItem) => {
          menuItem.addEventListener("mouseenter", () => {
            menuItem.classList.add("open");
          });

          menuItem.addEventListener("mouseleave", () => {
            menuItem.classList.remove("open");
          });
        });

        isSidebarInitialized = true;
      })
      .catch(error => console.error('Error cargando el menú:', error));
  }
}

// Función para generar el HTML del menú
function generateMenuHTML(menuData: MenuData): string {
  return Object.keys(menuData).map(key => {
    const mainItem = menuData[key];
    const hasSubmenu = mainItem.items && Object.keys(mainItem.items).length > 0;

    return `
      <li class="menu-item ${hasSubmenu ? 'menu-has-sub' : ''}">
        <a class="menu-link ${hasSubmenu ? 'menu-toggle' : ''}" 
          ${mainItem['data-module'] ? `data-module="${mainItem['data-module']}"` : ''} 
          ${mainItem['data-view'] ? `data-view="${mainItem['data-view']}"` : ''}
          ${mainItem.title ? `data-title="${mainItem.title}"` : ''}> 
          <i class="menu-icon ${mainItem.icon}"></i>
          <div data-i18n="${mainItem.title}">${mainItem.title}</div> 
        </a>
        ${hasSubmenu ? generateSubMenuHTML(mainItem.items!) : ''}
      </li>
    `;
  }).join('');
}


// Función para generar el HTML del submenú
function generateSubMenuHTML(subItems: { [key: string]: MenuItem }): string {
  return `
    <ul class="menu-sub">
      ${Object.keys(subItems).map(subKey => {
        const subItem = subItems[subKey];
        return `
          <li class="menu-item">
            <a class="menu-link" 
              ${subItem['data-module'] ? `data-module="${subItem['data-module']}"` : ''} 
              ${subItem['data-view'] ? `data-view="${subItem['data-view']}"` : ''}
              ${subItem.title ? `data-title="${subItem.title}"` : ''}> 
              <i class="menu-icon ${subItem.icon}"></i>
              <div data-i18n="${subItem.title}">${subItem.title}</div>
            </a>
          </li>
        `;
      }).join('')}
    </ul>
  `;
}

