type Route = {
    path: string;
    view: () => void;
};

class Router {
    
    private routes: Route[] = [];

    public addRoute(path: string, view: () => void) {
        this.routes.push({ path, view });
    }

    public navigate() {
        const currentPath = window.location.href.split('/')[3];
        const route = this.routes.find(r => r.path === currentPath);

        if (route) {
            route.view();
        } else {
            console.error(`No route found for path: ${currentPath}`);
        }
    }

    public init() {
        window.addEventListener('hashchange', () => this.navigate());
        window.addEventListener('load', () => this.navigate());
    }

}

export const router = new Router();
