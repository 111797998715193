import { TabArray } from "../../@types/TabArray";

export class TabState {

    private static state: {
        tabs: TabArray;
    } = {
        tabs: new TabArray()
    };
    
    static get<T extends keyof typeof TabState.state>(key: T): typeof TabState.state[T] {
        return TabState.state[key];
    }

    static set<T extends keyof typeof TabState.state>(key: T, value: typeof TabState.state[T]): void {
        TabState.state[key] = value;
    }

}