import { getCookie } from "../modules/base/auth/AuthHelpers";
import {
  loginToken,
  loginUsuario,
} from "../modules/common/usuario/application/Login";
import { usuarioStore } from "../modules/common/usuario/infraestructure/api/UsuarioStore";


// Inicializa la logica de login
export const initLogin = () => {
  const form = document.getElementById("formAuthentication") as HTMLFormElement;
  const userInput = document.getElementById("user") as HTMLInputElement;
  const passwordInput = document.getElementById("password") as HTMLInputElement;
  const rememberCheckbox = document.getElementById(
    "remember"
  ) as HTMLInputElement;
  const togglePassword = document.querySelector(
    ".input-group-text"
  ) as HTMLSpanElement;


  // Loguea el usuario
  if (form) {
    form.addEventListener("submit", async (event: Event) => {
      event.preventDefault();

      const user = userInput.value;
      const password = passwordInput.value;
      const remember = rememberCheckbox.checked;

      await loginUsuario(usuarioStore, user, password, remember);

      console.log("Usuario:", user);
      console.log("Password:", password);
      console.log("Recuérdame:", remember);
    });
  }

  // Marca el checkbox de recordar usuario si hay una cookie
  if (getCookie("user")) {
    (document.getElementById("user") as HTMLInputElement).value =
      getCookie("user") || "";
    document.getElementById("remember")?.setAttribute("checked", "true");
    loginToken(usuarioStore, true);
  }

  // Añadir el evento para alternar la visibilidad de la contraseña
  if (togglePassword) {
    togglePassword.addEventListener("click", () => {
      const type =
        passwordInput.getAttribute("type") === "password" ? "text" : "password";
      passwordInput.setAttribute("type", type);

      togglePassword.innerHTML =
        type === "password"
          ? '<i class="ti ti-eye-off"></i>'
          : '<i class="ti ti-eye"></i>';
    });
  }
};

// Carga el layout del login en app
export const loadLoginView = () => {
  const app = document.getElementById("main");

  if (app) {
    app.innerHTML = /*html*/ `

    <div class="authentication-wrapper authentication-basic container-p-y">
        <div class="authentication-inner py-6">
            <!-- Login -->
            <div class="card login-card">
            <div class="card-body">
                <!-- Logo -->
                <div class="app-brand justify-content-center mb-6">
                    <a href="index.html" class="app-brand-link">
                        <span class="app-brand-logo">
                            <img src="/public/logo_normal.svg" alt="Logo" />
                        </span>
                    </a>
                </div>
                <!-- /Logo -->
                <p class="mb-6">Por favor, introduce tus credenciales para iniciar sesión</p>

                <form id="formAuthentication" class="mb-4" action="index.html" method="GET">
                <div class="mb-6">
                    <label for="user" class="form-label">Correo o Usuario</label>
                    <input type="text" class="form-control" id="user" name="user" placeholder="Introduce tu correo o usuario" autofocus />
                </div>
                <div class="mb-6 form-password-toggle">
                    <label class="form-label" for="password">Contraseña</label>
                    <div class="input-group input-group-merge">
                    <input type="password" id="password" class="form-control" name="password"
                        placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;" aria-describedby="password" />
                    <span class="input-group-text cursor-pointer">
                        <i class="ti ti-eye-off"></i>
                    </span>
                    </div>
                </div>
                <div class="my-8">
                    <div class="d-flex justify-content-between">
                    <div class="form-check mb-0 ms-2">
                        <input class="form-check-input" type="checkbox" id="remember" />
                        <label class="form-check-label" for="remember">Recuérdame</label>
                    </div>
                    <a href="auth-forgot-password-basic.html">
                        <p class="mb-0">¿Olvidaste tu contraseña?</p>
                    </a>
                    </div>
                </div>
                <div class="mb-6">
                    <button class="btn btn-primary d-grid w-100" type="submit">Iniciar sesión</button>
                </div>
                </form>  
            </div>
            </div>
        </div>
    </div>
    `;
  }

  initLogin();
};
