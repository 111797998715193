import { AppState } from "../../base/state/domain/AppState";
import { usuarioStore } from "../usuario/infraestructure/api/UsuarioStore";
import { logout } from "../usuario/application/Login";
import { Modal } from "bootstrap";
import { loadTab } from "../../../views/main";
import { MenuItem, MenuData } from "../../base/@types/Menu";

let isHeaderInitialized = false;
let selectedShortcuts: Set<string> = new Set();

// Inicialización del header
export function initHeader(): void {
  if (isHeaderInitialized) {
    return;
  }

  const header = document.getElementById("header");
  if (header) {
    header.innerHTML = `
      <nav class="layout-navbar navbar navbar-expand-xl align-items-center bg-navbar-theme"
        id="layout-navbar">
        <a href="index.html" class="app-brand-link p-3">
        <span class="app-brand-logo demo">
          <img src="/logo_estacion.png" alt="Logo" width="32" height="32">
        </span>
        <span class="app-brand-text demo menu-text fw-bold" >Estación</span>
      </a>

        <div class="container-fluid">
          <div class="layout-menu-toggle navbar-nav align-items-xl-center me-3 me-xl-0 d-xl-none">
            <a class="nav-item nav-link px-0 me-xl-4" href="javascript:void(0)">
              <i class="ti ti-menu-2 ti-md"></i>
            </a>
          </div>

          <!-- Sección de Atajos -->
          <div class="navbar-shortcuts d-flex align-items-center">
            <ul id="shortcutsList" class="list-inline m-0 p-0 d-flex"></ul>
            <button id="addShortcutBtn" class="btn btn-warning btn-sm ms-2">
              <i class="fas fa-plus"></i>
            </button>
          </div>

          <div class="navbar-nav-right d-flex align-items-center ms-auto" id="navbar-collapse">
            <ul class="navbar-nav flex-row align-items-center ms-auto">
              <li class="nav-item me-3">
                <select id="companySelector" class="select2 form-select" data-allow-clear="true">
                  <option value="Company A">Company A</option>
                  <option value="Company B">Company B</option>
                  <option value="Company C">Company C</option>
                </select>
              </li>
              <li class="nav-item me-3">
                <select id="yearSelector" class="select2 form-select" data-allow-clear="true">
                  <option value="2022">2022</option>
                  <option value="2023">2023</option>
                  <option value="2024">2024</option>
                </select>
              </li>
              <li class="nav-item navbar-dropdown dropdown-user dropdown">
                <a class="nav-link dropdown-toggle hide-arrow p-0" href="javascript:void(0);"
                  data-bs-toggle="dropdown" role="button" id="btn_user">
                  <div class="avatar avatar-online">
                    <img src="/logo_estacion.png" alt class="rounded-circle" />
                  </div>
                </a>
                <ul class="dropdown-menu dropdown-menu-end">
                  <li><a class="dropdown-item" href="#"><i class="ti ti-user me-2"></i><span class="align-middle">Perfil</span></a></li>
                  <li><a class="dropdown-item" href="#"><i class="ti ti-settings me-2"></i><span class="align-middle">Configuraciones</span></a></li>
                  <li><div class="dropdown-divider"></div></li>
                  <li><button id="logout" type="button" class="dropdown-item btn btn-danger"><i class="ti ti-power me-2"></i><span class="align-middle">Desconectarse</span></button></li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    `;

    // Inicializar botón de añadir atajos
    const addShortcutBtn = document.getElementById("addShortcutBtn") as HTMLButtonElement | null;
    addShortcutBtn?.addEventListener("click", () => {
      openShortcutModal();
    });

    const companySelector = document.getElementById("companySelector") as HTMLSelectElement;
    companySelector.value = AppState.get("selectedCompany");
    companySelector.addEventListener("change", (e) => {
      const target = e.target as HTMLSelectElement;
      AppState.set("selectedCompany", target.value);
    });

    const yearSelector = document.getElementById("yearSelector") as HTMLSelectElement;
    yearSelector.value = AppState.get("currentYear");
    yearSelector.addEventListener("change", (e) => {
      const target = e.target as HTMLSelectElement;
      AppState.set("currentYear", target.value);
    });

    const logoutButton = document.getElementById("logout");
    logoutButton?.addEventListener("click", () => {
      logout(usuarioStore);
    });

    loadShortcuts(); // Cargar los atajos previamente guardados
    isHeaderInitialized = true;
  }
}

// Mostrar modal para seleccionar atajos
function openShortcutModal(): void {
  const viewList = document.getElementById("viewList") as HTMLUListElement;
  if (!viewList) return;

  fetch('src/config/sidebar.json')
    .then(response => response.json())
    .then(data => {
      viewList.innerHTML = generateAccordionHTML(data);

      // Registra el evento "change" después de generar el HTML
      document.getElementById("viewList")?.addEventListener("change", (event) => {
        const target = event.target as HTMLInputElement;
        const shortcut = target.value;

        if (target.checked) {
          selectedShortcuts.add(shortcut);
        } else {
          selectedShortcuts.delete(shortcut);
          removeShortcutButton(shortcut);
        }
      });
    })
    .catch(error => console.error('Error cargando los atajos:', error));

  const modalElement = document.getElementById("modalTop") as HTMLElement | null;
  if (modalElement) {
    const modal = new Modal(modalElement);
    modal.show();
  }
}

// Generar el HTML de la lista de atajos con comportamiento de acordeón
function generateAccordionHTML(menuData: MenuData): string {
  return Object.keys(menuData).map(key => {
    const mainItem = menuData[key];
    const hasSubmenu = mainItem.items && Object.keys(mainItem.items).length > 0;

    if (hasSubmenu) {
      return `
        <li class="list-group-item border-0 p-0">
          <div class="accordion" id="accordion-${key}">
            <div class="accordion-item border-0">
              <h2 class="accordion-header" id="heading-${key}">
                <button class="accordion-button collapsed d-flex align-items-center px-3 py-2" type="button" data-bs-toggle="collapse" 
                  data-bs-target="#collapse-${key}" aria-expanded="false" aria-controls="collapse-${key}">
                  <i class="${mainItem.icon} me-4"></i> ${mainItem.title}
                </button>
              </h2>
              <div id="collapse-${key}" class="accordion-collapse collapse" aria-labelledby="heading-${key}" data-bs-parent="#accordion-${key}">
                <div class="accordion-body">
                  <ul class="list-unstyled">
                    ${generateSubItemCheckboxes(mainItem.items!)}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </li>
      `;
    } else {
      return `
        <li class="list-group-item border-0 d-flex align-items-center p-2">
          <input type="checkbox" class="form-check-input me-2" id="shortcut-${key}" 
            value="${mainItem['data-module']}/${mainItem['data-view']}" 
            ${selectedShortcuts.has(`${mainItem['data-module']}/${mainItem['data-view']}`) ? 'checked' : ''}>
          <label class="form-check-label d-flex align-items-center" for="shortcut-${key}">
            <i class="${mainItem.icon} me-2"></i> ${mainItem.title}
          </label>
        </li>
      `;
    }
  }).join('');
}

// Generar los checkboxes para los subitems dentro de los acordeones
function generateSubItemCheckboxes(subItems: { [key: string]: MenuItem }): string {
  return Object.keys(subItems).map(subKey => {
    const subItem = subItems[subKey];
    return `
      <li class="list-group-item border-0 ms-4 d-flex align-items-center p-2">
        <input type="checkbox" class="form-check-input me-2" id="shortcut-${subKey}" 
          value="${subItem['data-module']}/${subItem['data-view']}" 
          ${selectedShortcuts.has(`${subItem['data-module']}/${subItem['data-view']}`) ? 'checked' : ''}>
        <label class="form-check-label d-flex align-items-center" for="shortcut-${subKey}">
          <i class="${subItem.icon} me-2"></i> ${subItem.title}
        </label>
      </li>
    `;
  }).join('');
}

// Guardar los atajos seleccionados
document.getElementById("saveShortcutBtn")?.addEventListener("click", () => {
  fetch('src/config/sidebar.json')
    .then(response => response.json())
    .then(menuData => {
      const shortcutsArray: { href: string; name: string; icon: string, module: string, view: string }[] = [];

      selectedShortcuts.forEach((shortcut) => {
        const [module, view] = shortcut.split("/");
        const icon = getIconForShortcut(module, view, menuData);
        const name = getTitleForShortcut(module, view, menuData);
        shortcutsArray.push({ href: `${module}/${view}`, name, icon, module, view });
      });

      localStorage.setItem("shortcuts", JSON.stringify(shortcutsArray));
      loadShortcuts(); // Recargar atajos en el header

      const modalElement = document.getElementById("modalTop") as HTMLElement | null;
      if (modalElement) {
        const modal = Modal.getInstance(modalElement);
        modal?.hide();
      }
    })
    .catch(error => console.error('Error cargando el JSON del menú:', error));
});

// Crear el botón en el header para el atajo
function createShortcutButton(shortcut: string, icon: string, module: string, view: string, name: string): void {
  const shortcutsList = document.getElementById("shortcutsList") as HTMLUListElement;
  if (!shortcutsList) return;
  shortcut
  const listItem = document.createElement("li");
  listItem.classList.add("list-inline-item");

  const button = document.createElement("a");
  button.href = "javascript:void(0);";
  button.classList.add("btn", "btn-secondary", "btn", "me-1", "waves-effect", "waves-light");
  button.innerHTML = `<i class="${icon}"></i>`;
  button.id = `shortcut-btn-${module}-${view}`;
  button.setAttribute('data-module', module);
  button.setAttribute('data-view', view);

  button.addEventListener("click", () => {
    loadTab(module, view, name);
  });

  listItem.appendChild(button);
  shortcutsList.appendChild(listItem);
}

// Eliminar el botón del header cuando el usuario desmarca el atajo
function removeShortcutButton(shortcut: string): void {
  const button = document.getElementById(`shortcut-btn-${shortcut}`);
  if (button) {
    button.remove();
  }
}

// Función para obtener el título del atajo desde el JSON
function getTitleForShortcut(module: string, view: string, menuData: MenuData): string {
  for (const sectionKey in menuData) {
    const section = menuData[sectionKey];
    if (section.items) {
      for (const itemKey in section.items) {
        const item = section.items[itemKey];
        if (item['data-module'] === module && item['data-view'] === view) {
          return item.title;
        }
      }
    } else if (section['data-module'] === module && section['data-view'] === view) {
      return section.title;
    }
  }
  return "Atajo";
}

// Función para obtener el ícono del atajo desde el JSON
function getIconForShortcut(module: string, view: string, menuData: MenuData): string {
  for (const sectionKey in menuData) {
    const section = menuData[sectionKey];
    if (section.items) {
      for (const itemKey in section.items) {
        const item = section.items[itemKey];
        if (item['data-module'] === module && item['data-view'] === view) {
          return item.icon;
        }
      }
    } else if (section['data-module'] === module && section['data-view'] === view) {
      return section.icon;
    }
  }
  return "fas fa-question"; // Ícono por defecto si no se encuentra
}

// Cargar los atajos guardados en localStorage
function loadShortcuts(): void {
  const shortcuts: { href: string; name: string; icon: string, module: string, view: string }[] = JSON.parse(
    localStorage.getItem("shortcuts") || "[]"
  );
  const shortcutsList = document.getElementById("shortcutsList") as HTMLUListElement;
  if (!shortcutsList) return;
  shortcutsList.innerHTML = ""; // Limpiar lista previa

  // Añadir los botones de los atajos guardados
  shortcuts.forEach((shortcut) => {
    selectedShortcuts.add(shortcut.href);
    console.log(shortcut);
    createShortcutButton(shortcut.href, shortcut.icon, shortcut.module, shortcut.view, shortcut.name);
  });
}
