import { BASE_URL } from "../../../../env"
import { getCookie } from "../../../../modules/base/auth/AuthHelpers";
import ErrorCodes from "../../../../modules/base/exceptions/ErrorCodes";

let bearer = 'Bearer ';
const token = getCookie('auth-token');
if (token != null) {
    bearer = bearer + token;
}

const headers = {
    'Content-Type': 'apllication/json',
    'Authorization': bearer
}

function updateHeader() {
    const token = getCookie('auth-token');
    if (token != null) {
        bearer = 'Bearer ' + token;
    } else {
        bearer = '';
    }
    headers['Authorization'] = bearer;
}


const get = async <T>(url: string) => {
    updateHeader();
    const response = await fetch(BASE_URL + url, {
        method: 'GET',
        credentials: 'include',
        headers
    })
    return await ErrorCodes.manageResponse(response) as T
}

const post = async <T>(url: string, body: any) => {
    updateHeader();
    const response = await fetch(BASE_URL + url, {
        method: 'POST',
        credentials: 'include',
        headers,
        body
    })
    return await ErrorCodes.manageResponse(response) as T
    
}

const put = async <T>(url: string, body: any) => {
    updateHeader();
    const response = await fetch(BASE_URL + url, {
        method: 'PUT',
        credentials: 'include',
        headers,
        body
    })
    return await ErrorCodes.manageResponse(response) as T
}

const _delete = async <T>(url: string) => {
    updateHeader();
    const response = await fetch(BASE_URL + url, {
        method: 'DELETE',
        credentials: 'include',
        headers
    })

    return await ErrorCodes.manageResponse(response) as T
}

export const http = {
    get,
    post,
    put,
    delete: _delete
}
