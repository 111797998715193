import toastr from 'toastr';
import 'toastr/build/toastr.css';
import { logout } from "../../../modules/common/usuario/application/Login";
import { usuarioStore } from "../../../modules/common/usuario/infraestructure/api/UsuarioStore";

export default class ErrorCodes {
    private static RESPONSE_OK = 200;
    private static STORED_OK = 201;

    // Errores
    private static UNKNOW_ERROR = 520;
    private static SERVER_BUSY = 521;
    private static INVALID_CONTENT = 400;
    private static PROCESSING_ERROR = 500;
    private static INVALID_AUTHORIZATION = 401;
    private static EXPIRATED_AUTHORIZATION = 403;
    private static AUTHORIZATION_REQUIRED = 499;

    // Descripciones
    private static errorDescriptions = {
        [this.UNKNOW_ERROR]: "Error desconocido",
        [this.SERVER_BUSY]: "Servidor ocupado",
        [this.INVALID_CONTENT]: "Contenido invalido",
        [this.PROCESSING_ERROR]: "Error de procesamiento",
        [this.INVALID_AUTHORIZATION]: "No dispone de autorización",
        [this.RESPONSE_OK]: "OK",
        [this.STORED_OK]: "Almacenado correctamente",
        [this.EXPIRATED_AUTHORIZATION]: "Su sesión ha expirado",
        [this.AUTHORIZATION_REQUIRED]: "Se requiere autorización"
    };

    /**
     *  Devuelve la descripcion del error
     * @param errorCode 
     * @returns 
     */
    public static getErrorDescription(errorCode: number) {
        return this.errorDescriptions[errorCode];
    }

    /**
     * Según el código HTTP, se gestiona la respuesta
     * @param errorCode 
     * @param response 
     * @returns 
     */
    public static manageResponse(response: Response) {
        console.log(response.status);
        switch (response.status) {
            case this.RESPONSE_OK:
            case this.STORED_OK:
                return response.json();
            case this.UNKNOW_ERROR:
            case this.SERVER_BUSY:
            case this.INVALID_CONTENT:
            case this.PROCESSING_ERROR:
                toastr.error(this.errorDescriptions[response.status], 'Error');
                return response.json();
            case this.INVALID_AUTHORIZATION:
            case this.EXPIRATED_AUTHORIZATION:
                toastr.error(this.errorDescriptions[response.status], 'Error de Autorización');
                
                // Añadimos un pequeño retraso antes de ejecutar el logout
                setTimeout(() => {
                    logout(usuarioStore);
                }, 1000); 

                return null;
            case this.AUTHORIZATION_REQUIRED:
                toastr.warning(this.errorDescriptions[response.status], 'Autorización Requerida');
                
                // Retraso antes de redirigir al login
                setTimeout(() => {
                    if (!window.location.href.includes('/login')) {
                        window.location.href = '/login';
                    }
                }, 2000); 

                return null;
            default:
                return response;
        }
    }
}
