
/**
 * Parsea el token JWT y devuelve el payload
 * @param token 
 * @returns 
 */
export function parseJwt (token: string) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}

/**
 * Devuelve la cookie indicada
 * @param cname 
 * @returns 
 */
export function getCookie(cname:string) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  /**
   * Guarda una cookie
   * @param cname //Nombre de la cookie
   * @param cvalue //Valor de la cookie
   * @param exdays //Caducidad de la cookie
   */
  export function setCookie(cname:string, cvalue:string, exdays:number) {
    const d = new Date();
    d.setTime(d.getTime() + (exdays*24*60*60*1000));
    let expires = "expires="+ d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";SameSite=Strict;path=/";
  }

  /**
   * Borra una cookie poniendo la fehca de caducidad a una fecha pasada
   * @param cname 
   */
  export function deleteCookie(cname:string) {
    let expires = "Thu, 01 Jan 1970 00:00:00";
    document.cookie = cname + "=; expires=" + expires + ";SameSite=Strict;path=/";
  }
