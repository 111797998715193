import { UsuarioDTO } from "./UsuarioDTO";
import { UsuarioStoreIF } from "./UsuarioStoreIF";
import { ColumnConfig } from "../../../base/@types/DataTableEditable";
import { Rol } from "../../rol/domain/Rol";

export class Usuario {

    private _id: number;
    private _nombre: string;
    private _pass?: string;
    private _rol: number | Rol;
    private _favoritos?: number[];
    private _impresoras?: number[];
    private _version?: string;

    constructor(id: number, nombre: string, pass: string = '', rol: number | Rol, favoritos: number[] = [], impresoras: number[] = [], version: string = '') {
        this._id = id;
        this._nombre = nombre;
        this._pass = pass;
        this._rol = rol;
        this._favoritos = favoritos;
        this._impresoras = impresoras;
        this._version = version;
    }

    //** CLASS METHODS */

    set id(id: number) {
        this._id = id;
    }

    get id() {
        return this._id;
    }

    set nombre(nombre: string) {
        this._nombre = nombre;
    }

    get nombre() {
        return this._nombre;
    }

    set pass(pass: string) {
        this._pass = pass;
    }

    get pass() {
        return this._pass || '';
    }

    set rol(rol: number | Rol) {
        this._rol = rol;
    }

    get rol() {
        return this._rol;
    }

    set favoritos(favoritos: number[]) {
        this._favoritos = favoritos;
    }

    get favoritos() {
        return this._favoritos || [];
    }

    set impresoras(impresoras: number[]) {
        this._impresoras = impresoras;
    }   

    get impresoras() {
        return this._impresoras || [];
    }

    set version(version: string) {
        this._version = version;
    }

    get version() {
        return this._version || '';
    }

    //** CRUD METHODS */

    public create(usuarioStore: UsuarioStoreIF) {
        return usuarioStore.create(this);
    }

    public update(usuarioStore: UsuarioStoreIF) {
        return usuarioStore.update(this);
    }

    public delete(usuarioStore: UsuarioStoreIF) {
        return usuarioStore.delete(this._id);
    }

    //** STATIC CRUD METHODS */

    public static read(usuarioStore: UsuarioStoreIF, id: number) {
        return usuarioStore.read(id);
    }

    public static readAll(usuarioStore: UsuarioStoreIF) {
        return usuarioStore.readAll();
    }

    public static next(usuarioStore: UsuarioStoreIF, id: number) { 
        return usuarioStore.next(id);
    }

    public static previous(usuarioStore: UsuarioStoreIF, id: number) {
        return usuarioStore.previous(id);
    }

    /** STATIC CLASS METHODS */

    public static fromDTO(usuarioDTO: UsuarioDTO) {
        return new Usuario(
            usuarioDTO.id,
            usuarioDTO.nombre,
            usuarioDTO.pass,
            usuarioDTO.rol,
            usuarioDTO.favoritos,
            usuarioDTO.impresoras,
            usuarioDTO.version
        )
    }

    public static toJson(usuario: Usuario) {
        return JSON.stringify({
            id: usuario.id,
            nombre: usuario.nombre,
            pass: usuario.pass,
            rol_fk: usuario.rol,
            favoritos: usuario.favoritos,
            impresoras: usuario.impresoras,
            version: usuario.version
        });
    }

    /** TABLE CONFIGS */
    static tableConfig : ColumnConfig[] = [
        {
            title: "ID",
            data: "id",
            render: function(data: number) {
                return `<div contenteditable="true">${data}</div>`;
            }
        },
        {
            title: "Nombre",
            data: "nombre",
            render: function(data: string) {
                return `<div contenteditable="true">${data}</div>`;
            },
            validate: function(data: string) {
                return data.length > 0; 
            }
        },
        {
            title: "Contraseña",
            data: "pass",
            render: function(data: string) {
                return `<div contenteditable="true">${data}</div>`;
            },
            validate: function(data: string) {
                return data.length > 0; 
            }
        },
        {
            title: "Rol",
            data: "rol",
            render: function(data: number) {
                return `<div contenteditable="true">${data}</div>`;
            }
        },
        {
            title: "Versión",
            data: "version",
            render: function(data: string) {
                const formattedDateTime = data.replace(' ', 'T').slice(0, 16);
                return `<input contenteditable="true" type="datetime-local" value="${formattedDateTime}" />`;
            }
        }
    ]

}

