import { Usuario } from "../../domain/Usuario";
import { http } from "../../../../../modules/base/http/infraestructure/http";
import { UsuarioDTO } from "../../domain/UsuarioDTO";
import { UsuarioStoreIF } from "../../domain/UsuarioStoreIF";
import { AppState } from "../../../../base/state/domain/AppState";
import { deleteCookie, setCookie } from "../../../../../modules/base/auth/AuthHelpers";

export const usuarioStore: UsuarioStoreIF = {
    readAll: async () => {
        const usuarios = await http.get<UsuarioDTO[]>('/common/usuarios');
        return usuarios.map((usuarioDTO) => Usuario.fromDTO(usuarioDTO));
    },
    read: async (id: number) => {
        const usuarioDTO = await http.get<UsuarioDTO>(`/common/usuarios/${id}`);
        return Usuario.fromDTO(usuarioDTO);
    },
    next: async (id: number) => {
        const usuarioDTO = await http.get<UsuarioDTO>(`/common/usuarios/${id}/next`);
        return Usuario.fromDTO(usuarioDTO);
    },
    previous: async (id: number) => {
        const usuarioDTO = await http.get<UsuarioDTO>(`/common/usuarios/${id}/previous`);
        return Usuario.fromDTO(usuarioDTO);
    },
    create: async (user: Usuario) => {
        const usuarioDTO = await http.post<UsuarioDTO>('/common/usuarios', Usuario.toJson(user));
        return Usuario.fromDTO(usuarioDTO);
    },
    update: async (user: Usuario) => {
        const usuarioDTO = await http.put<UsuarioDTO>(`/common/usuarios/${user.id}`, Usuario.toJson(user));
        return Usuario.fromDTO(usuarioDTO);
    },
    delete: async (id: number) => {
        const result = await http.delete(`/common/usuarios/${id}`);
        return result;
    },
    login: async (user: string, password: string, remember: boolean) => {
        const data = {
            'usuario': user,
            'password': password
        };
        let usuario = await http.post<UsuarioDTO>('/common/usuarios/login', JSON.stringify(data));
        remember ? setCookie('user', user, 30) : deleteCookie('user');
        AppState.set('user', Usuario.fromDTO(usuario));
        window.location.href = '/';
    },
    logout: async () => {
        await http.post<boolean>('/common/usuarios/logout', '');
        AppState.set('user', undefined);
        window.location.href = '/login';
    },
    loginToken: async (redirect: boolean) => {
        let usuario = await http.post<UsuarioDTO>('/common/usuarios/loginT', '');
        if(redirect && usuario != null && usuario.id > 0){
            AppState.set('user', Usuario.fromDTO(usuario));
            window.location.href = '/';
        }
    }
};

