
import { UsuarioStoreIF } from "../domain/UsuarioStoreIF";

export async function loginUsuario(usuarioStoreIF:UsuarioStoreIF, user: string, password: string, remember: boolean) : Promise<void> {
     usuarioStoreIF.login(user, password , remember);
}

export async function loginToken(usuarioStoreIF:UsuarioStoreIF, redirect: boolean) : Promise<void> {
     usuarioStoreIF.loginToken(redirect);
}

export async function logout(usuarioStoreIF:UsuarioStoreIF) : Promise<void> {
     usuarioStoreIF.logout();
}
