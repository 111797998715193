import { Usuario } from "../../../common/usuario/domain/Usuario";

export class AppState {

    private static state: {
        selectedCompany: string;
        currentYear: string;
        user?: Usuario;
    } = {
        selectedCompany: 'Company A',
        currentYear: new Date().getFullYear().toString(),
    };

    private static listeners: Array<() => void> = [];

    static get<T extends keyof typeof AppState.state>(key: T): typeof AppState.state[T] {
        return AppState.state[key];
    }

    static set<T extends keyof typeof AppState.state>(key: T, value: typeof AppState.state[T]): void {
        AppState.state[key] = value;
        AppState.notify();
    }

    static onChange(listener: () => void): void {
        AppState.listeners.push(listener);
    }

    private static notify(): void {
        AppState.listeners.forEach(listener => listener());
    }
}

