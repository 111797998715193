import { initHeader } from "../modules/common/components/header";
import { initSidebar } from "../modules/common/components/sidebar";
import { Module } from "../modules/base/@types/Module";
import { TabState } from "../modules/base/state/domain/TabState";
// Usando `import.meta.glob` se almacenan los módulos dinámicos en un array
const modules = import.meta.glob("/src/modules/**/*.ts");

// Instancia global de la clase State para manejar las pestañas

// Crea una nueva pestaña y carga el contenido
export function loadTab(moduleName: string, viewName: string, tabTitle: string) {
  
  const tabContainer = document.getElementById("tab-container");
  const contentContainer = document.getElementById("content");

  // Verifica si el contenedor de contenido existe
  if (!contentContainer) {
    console.error("El contenedor principal de contenido no existe.");
    return;
  }

  // Generar un identificador único para cada tab y contenido (usando Date.now para permitir duplicados)
  const uniqueId = `${moduleName}-${viewName}-${Date.now()}`;
  const tabId = `tab-${uniqueId}`;
  const contentId = `content-${uniqueId}`;

  if (tabContainer && contentContainer) {
    // Verificar si la pestaña ya existe en el estado
    if (TabState.get("tabs").hasTab(tabId)) {
      switchTab(contentId);
      return;
    }

    // Inicializa header y sidebar solo una vez
    initHeader();
    initSidebar();

    // Guardar la referencia en el estado global usando la clase State
    TabState.get("tabs").addTab(tabId, moduleName, viewName);

    // Crea la nueva pestaña
    const newTab = document.createElement("li");
    newTab.className = "tab nav-item";
    newTab.role = "presentation";
    tabContainer.appendChild(newTab);

    const button = document.createElement("button");
    button.className = "nav-link waves-effect";
    button.dataset.bsToggle = "tab";
    button.dataset.bsTarget = `#navs-top-home`;
    button.ariaSelected = "true";
    button.innerHTML = `${tabTitle}`;

    const closebutton = document.createElement("button");
    closebutton.className = "btn-close small-close";
    closebutton.ariaLabel = "Close";
    button.appendChild(closebutton);

    button.onclick = (e) => {
      if (e.target && (e.target as HTMLElement).classList.contains("btn-close"))
        return;

      switchTab(contentId);
    };
    button.role = "tab";
    button.id = tabId;
    newTab.appendChild(button);

    // Evento para cerrar la pestaña al hacer clic en la "X"
    button.querySelector(".btn-close")?.addEventListener("click", (e) => {
      e.stopPropagation();
      closeTab(tabId, contentId);
    });

    // Crear el contenedor de contenido para la pestaña dentro del main
    const newContent = document.createElement("div");
    newContent.className = "tab-pane fade";
    newContent.role = "tabpanel";
    newContent.id = contentId;
    newContent.dataset.bsTab = "tab";

    contentContainer.appendChild(newContent);

    // Infla el contenido en la nueva pestaña
    inflateContent(newContent, moduleName, viewName);

    // Cambia a la pestaña recién creada
    switchTab(contentId);
  }
}

// Cambia el contenido visible al hacer clic en una pestaña
function switchTab(contentId: string) {
  const contentContainer = document.getElementById("content");

  // Desactivar todas las pestañas y sus contenidos
  document.querySelectorAll(".tab-pane").forEach((content) => {
    content.classList.remove("show", "active");
  });

  document.querySelectorAll(".nav-link").forEach((tab) => {
    tab.classList.remove("active");
  });

  // Activar la pestaña correspondiente
  const activeTab = document.getElementById(`tab-${contentId.replace("content-", "")}`);
  if (activeTab) {
    activeTab.classList.add("active");
  }

  // Mostrar el contenido correspondiente
  const activeContent = document.getElementById(contentId);
  if (activeContent) {
    // Mover el contenido al principio del contenedor
    if (contentContainer) {
      contentContainer.insertBefore(activeContent, contentContainer.firstChild);
    }
    activeContent.classList.add("show", "active");
  }
}




// Infla el contenido en el tab correspondiente
function inflateContent(
  container: HTMLElement | null,
  moduleName: string,
  viewName: string
) {
  if (!container) {
    console.error(`No se encontró el contenedor para la vista: ${viewName}`);
    return;
  }

  const path = `/src/modules/${moduleName}/views/${viewName}.ts`;

  if (modules[path]) {
    modules[path]()
      .then((module) => {
        const typedModule = module as Module;
        if (typeof typedModule.init === "function") {
          typedModule.init(container);
        }
      })
      .catch((err) => {
        console.error(`Error cargando la vista ${viewName}:`, err);
      });
  } else {
    console.error(`No se encontró la vista: ${viewName}`);
  }
}

// Función para cerrar la pestaña
function closeTab(tabId: string, contentId: string) {
  // Elimina la pestaña del estado
  TabState.get("tabs").removeTab(tabId);

  // Elimina el elemento de la pestaña del DOM
  const tabElement = document.getElementById(tabId)?.parentElement;
  if (tabElement) {
    tabElement.remove();
  }

  // Elimina el contenido correspondiente del DOM
  const contentElement = document.getElementById(contentId);
  if (contentElement) {
    contentElement.remove();
  }


  // Cambia a la primera pestaña si queda alguna
  const firstTab = document.querySelector("#tab-container .tab button");
  if (firstTab) {
    const firstContentId = firstTab.id.replace("tab-", "content-");
    switchTab(firstContentId);
  }
}

// Carga el layout principal sobre el que se inflarán las vistas
export const loadLayout = () => {
  const app = document.getElementById("main");
  if (app) {
    app.innerHTML = `
    <div class="layout-wrapper layout-navbar-full layout-horizontal layout-without-menu">
    <div class="layout-container">
      <div id="header">
      </div>
  
      <div class="layout-page">
        <div class="content-wrapper">
          <div id="sidebar">
          </div>
          <div class="" style="height:calc(100% - 110px);">
            <!-- Menú de pestañas que será estático -->
            <div class="nav-align-top nav-tabs-shadow" style="z-index: 1030;">
              <ul class="nav nav-tabs" role="tablist" id="tab-container">
              </ul>
            </div>
  
            <!-- Contenido desplazable dentro del área fija -->
            <div id="content" class="content" style="background-color: white;">
            </div>
          </div>
        </div>
      </div>
      <div class="content-backdrop fade"></div>
    </div>
    <div class="layout-overlay layout-menu-toggle"></div>
    <div class="drag-target"></div>
  </div>
  
    `;

  } else {
    console.error("No se encontró el contenedor principal de la aplicación.");
  }
};
