import { Tab } from "./Tab"

export class TabArray {

    private static tabs: Tab[] = [];

     public addTab(tabId: string, moduleName: string, viewName: string) {
        TabArray.tabs.push({ tabId, moduleName, viewName });
    }

    public getTabs() {
        return TabArray.tabs;
    }

    public hasTab(tabId: string): boolean {
        return TabArray.tabs.some(tab => tab.tabId === tabId);
    }

    public getTab(tabId: string) {
        return TabArray.tabs.find(tab => tab.tabId === tabId);
    }

    public removeTab(tabId: string) {
        TabArray.tabs = TabArray.tabs.filter((tab) => tab.tabId !== tabId);
      }

}